import styled from 'styled-components';
import IconArrowGreenRight from '../../images/icon-arrow-yellow-right.png';

export const Body = styled.div`
  text-align: center;
  @media (max-width: 991px) {
    padding: 15px;
  }
`;
export const ImageStyle = styled.img`
  width: auto;
  height: 150px;
  border: 0;
  vertical-align: top;
  margin: 0 0 30px 0;
  @media (max-width: 991px) {
    height: 150px;
  }
`;
export const LinkStyle = styled.a`
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  color: #0e4c80;
  transition: 0.25s ease-out;
`;
export const Header3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #0e4c80;
  @media (max-width: 1439px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const ParaText = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 17px 35px 13px 35px;
    margin: 0 5px 5px 0;
    font-family: 'Adelle-Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: bold;
    color: #55aa4c;
    border: 2px solid #55aa4c;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 767px) {
      padding: 10px 20px 6px 20px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
    }
    :hover {
      top: -5px;
      color: #55aa4c;
      border-color: #55aa4c;
      box-shadow: 0 5px 0 0 #55aa4c;
    }
  }
  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
  }
`;
export const ListContainer = styled.ul`
  padding: 0;
  margin: 0 0 30px 0;
`;
export const ListText = styled.li`
  padding: 0 0 15px 30px;
  background: url(${IconArrowGreenRight}) no-repeat 0 9px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  ${ParaText} {
    margin: 0;
  }
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
export const HeaderH2 = styled.h2`
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #0e4c80;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const ItalicParaText = styled(ParaText)`
  font-style: italic;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;
export const Header = styled.h1`
  font-family: 'Adelle-Bold', sans-serif;
  font-weight: normal !important;
  color: #0e4c80;
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 10px;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
